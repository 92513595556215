const env = process.env.NODE_ENV || 'development';

const apiEnvironment = {
  development: {
    api: 'https://laocai-api.cgis.asia/',
    apiCustom: 'https://pahtsonla-api.cgis.asia',
    domainUserSide: 'http://localhost:3000',
    domainAdminSide: 'http://localhost:3000',
    domainName: '',
    doMainNameGeoServer: 'https://geo.cgis.asia/geoserver/wfs',
    doMainNameGeoServerWMS: 'https://geo.cgis.asia/geoserver/laocai/wms/',
    datastore: 'laocai',
  },
  production: {
    api: 'https://laocai-api.cgis.asia/',
    apiCustom: 'https://pahtsonla-api.cgis.asia',
    domainUserSide: 'https://laocai.cgis.asia',
    domainAdminSide: 'https://admin.laocai.cgis.asia',
    domainName: 'cgis.asia',
    doMainNameGeoServer: 'https://geo.cgis.asia/geoserver/wfs',
    doMainNameGeoServerWMS: 'https://geo.cgis.asia/geoserver/laocai/wms/',
    datastore: 'laocai',
  },
};

module.exports = apiEnvironment[env];
