import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./select-current-layer.scss";
import LayerSettingsModels from "../../../models/map-data-model-b/layer-setting-models";
import { connect } from "react-redux";
import OpenlayerController from "../../../models/open-layer/openlayer-controller-custom.model";

interface LayerSettingsModelExtends
  extends LayerSettingsModels.LayerSettingsModel {
  label: string;
  value: string;
  opacity: string;
}

interface InitProps {
  listLayers: LayerSettingsModels.LayerSettingsModel[];
  openLayerController?: OpenlayerController;
}

function AddLabelValueToListLayers(
  listLayer: LayerSettingsModels.LayerSettingsModel[]
): LayerSettingsModelExtends[] {
  const result: LayerSettingsModelExtends[] = [];
  listLayer.map((layerObject) =>
    result.push({
      ...layerObject,
      label: layerObject.name,
      value: String(layerObject.id),
      opacity: "100",
    })
  );
  return result;
}

function SelectCurrentLayerView(props: InitProps) {
  const [layer, setLayer] = useState<any>();
  const [listLayers, setListLayers] = useState<LayerSettingsModelExtends[]>([]);

  useEffect(() => {
    if (props.listLayers && props.listLayers.length > 0) {
      let layers = AddLabelValueToListLayers(props.listLayers);
      setLayer(layers[0]);
      setListLayers(layers);
    }
  }, [props.listLayers]);

  const handleChangeCurrentLayer = (
    selectedObject: LayerSettingsModelExtends | any,
    actionDetail: any
  ) => {
    setLayer(selectedObject);
    props.openLayerController?.changeCurrentLayer(selectedObject);
  };

  const handleChangeOpacityOfCurrentLayer = (event: any) => {
    let data = {
      ...layer,
      opacity: event.target.value,
    };

    let dataList = listLayers.map((item) => {
      if (item.value === data.value) {
        item.opacity = data.opacity;
      }
      return item;
    });
    let opacity = Number(data.opacity) / 100;
    props.openLayerController?.changeCurrentLayerOpacity(opacity, data);
    setListLayers(dataList);
    setLayer(data);
  };

  if (listLayers.length === 0) return null;

  return (
    <div id="map-tool-panel-right-base-map"  className="position-relative select-base-map-container">
      <div  className="position-absolute select-base-map bg-white pt-4 pl-2 pr-2 pb-2 rounded">
        <div className="border-top pt-2">
          <label className="text-secondary">Chọn lớp cơ sở:</label>
          <Select
            defaultValue={layer}
            onChange={(selected, action) =>
              handleChangeCurrentLayer(selected, action)
            }
            options={listLayers}
            placeholder="Lớp hiện hành"
          />
          <input
            id="ranger-input-current-layer"
            onChange={handleChangeOpacityOfCurrentLayer}
            className="w-100 opacity-range-control"
            type="range"
            value={layer.opacity}
            min="0"
            max="100"
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  openLayerController: state.openlayer.openLayerController,
});

export default connect(mapStateToProps, null)(SelectCurrentLayerView);
