import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './layout-profile.scss';
import HeaderView from '../header/header.view.jsx';
import LoadingWithApiQueue from '../loading/loading-with-queue.view';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faLock, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import UrlCollect from '../../common/url-collect';
import ViLanguage from "../../languages/vi";
import { DomainUserSide, removeCookies, setCookies, TokenKey } from 'utils/configuration';
import { LayoutAuthen } from '../layout-authen'

const LanguageCollect = ViLanguage;

export default function (props) {
  const { pathname } = useLocation();

  const onLogout = () => {
    removeCookies(TokenKey.token);
    removeCookies(TokenKey.refreshToken);
    window.location.replace(DomainUserSide);
    setCookies("SHOW_FORM_LOGIN", true);
    setCookies("IS_ADMIN", false);
  }

  return (
    <LayoutAuthen {...props}>
      <div id='layout-profile-container' className='container pt-5 pb-4'>
        <div className='row'>
          <div className='col-sm-3 left-menu'>
            <div className='left-nav'>
              <ul className='nav rounded'>
                {listNav.map((x) => (
                  <Link
                    className={`nav-item-profile ${x?.url === pathname ? 'bg-item-selected' : 'bg-item'}`}
                    to={x.url}
                    onClick={() => x.id === 5 ? onLogout() : null}
                  > {x.icon} <span className='ml-2'>{x.name}</span>
                  </Link>
                ))}
              </ul>
            </div>
          </div>
          <div className='col-sm-9'>
            {props.children}
          </div>
        </div>
      </div>
      <LoadingWithApiQueue />
    </LayoutAuthen>
  );
}

const listNav = [
  {
    id: 1,
    name: LanguageCollect.user_info,
    icon: <FontAwesomeIcon icon={faUser} />,
    url: UrlCollect.UserInfo
  },
  // {
  //   id: 2,
  //   name: LanguageCollect.reflect_recommendations,
  //   icon: <FontAwesomeIcon icon={faUser} />,
  //   url: UrlCollect.CreateReflect
  // },
  // {
  //   id: 3,
  //   name: LanguageCollect.header_myReflect,
  //   icon: <FontAwesomeIcon icon={faHeart} />,
  //   url: UrlCollect.MyReflect
  // },
  {
    id: 4,
    name: LanguageCollect.change_password,
    icon: <FontAwesomeIcon icon={faLock} />,
    url: UrlCollect.ResetPassword
  },
  {
    id: 5,
    icon: <FontAwesomeIcon icon={faSignOutAlt} />,
    name: LanguageCollect.header_logout,
  }
]
