/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as apiConfig from '../../../api/api-config';
import {
  faAngleLeft,
  faAngleRight,
  faBook,
  faGavel,
  faTrashAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import "./left-menu.scss";
import ViLanguages from "../../../languages/vi";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import LeftMenuModels from "../../../models/planning-map-view/left-menu.model";
import { connect } from "react-redux";
import LayerCategoryModels from "../../../models/map-data-model-b/layer-category-models";
import LayerSettingsModels from "../../../models/map-data-model-b/layer-setting-models";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import PlanningRelationModel, {
  PlanningMapModels,
  BoundariesViewModel,
  RelatedLayerModel,
} from "../../../models/map-data-model-b/new-planning-relation.model";

import InfoPlanning from "./info/planning";
import InfoDocument from "./info/document";
import InfoNote from "./info/note";

import ReactTooltip from "react-tooltip";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { getCookies, NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../languages/vi-VN.json";
import { isMobile } from "react-device-detect";
import Related from "./related/related";
import * as StatementAction from "../../../redux/store/statement/statement.store";
import { PlanningModelMapTool } from "models/planning-map-view/top-right-panel-models";
import { Height } from "@material-ui/icons";
const LanguageCollects = ViLanguages;

function LeftMenuView(props: LeftMenuModels.LeftMenuProps) {
  const [isShowMapResult, setShowMapResult] = useState(false);
  const [headerTable, setHeaderTable] = useState<any>();
  const [planningModel, setPlanningModel] = useState<PlanningModelMapTool>();

  useEffect(() => {
    setHeaderTable(props.headerTable);
  }, [props.headerTable]);

  function handleClickIconLeftNavbar(id: string) {
    handleClickToggleLeftNavBar();
    const buttonElement = document.querySelector(
      `button[aria-controls="${id}"]`
    ) as HTMLElement;
    if (buttonElement.classList.contains("collapsed")) buttonElement.click();
  }

  function handleClickToggleLeftNavBar() {
    props.toggleStateIsLeftNavbarHide();
    if (!props.isLeftNavbarHide) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("hide-left-navbar");
    } else {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("hide-left-navbar");
    }
  }

  function handleToggleDisplayLayer(
    layerTarget: LayerSettingsModels.LayerSettingsModel
  ) {
    props.openLayerController?.toggleDisplayLayer(layerTarget);
  }

  function handleToggleDisplayBoundaries(
    boundariesObject: BoundariesViewModel,
    relatedLayerObject: RelatedLayerModel,
    isDisplay: boolean
  ) {
    props.openLayerController.toggleDisplayLayerRelated(
      boundariesObject,
      relatedLayerObject,
      isDisplay
    );
  }

  function handleToggleShowAllMapResult() {
    if (!isShowMapResult) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("show-map-result");
      setShowMapResult(true);
    } else {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("show-map-result");
      setShowMapResult(false);
    }
  }

  useEffect(() => {
    props.GetListPlanningRelationByPlanningId(props.planningId);
    onGetDetailPlanningById(props.planningId);
    if (isMobile) {
      handleClickToggleLeftNavBar();
    }
  }, []);

  const onGetDetailPlanningById = (planningId: number) => {
    StatementAction.GetDetailPlanningById(planningId).then((res) => {
      const result = (res && res.content) || null;
      setPlanningModel(result);
      console.log(result);
    });
  };

  // when IsLeftNavbarHide
  const onClickIcon = () => {
    if (props.isLeftNavbarHide) {
      handleClickToggleLeftNavBar()
    }
  }

  const [showNote, setShowNote] = useState(false);
  const [noteData, setNoteData] = useState(null);
  const [isAdmin,setIsAdmin] = useState(false);
  const getAdmin = getCookies('IS_ADMIN');
  useEffect(() => {
    setIsAdmin(getCookies('IS_ADMIN') === 'true'? true : false)
    console.log(getCookies('IS_ADMIN') === 'true'? true : false)
  },[])
  useEffect(() => {
    setIsAdmin(getAdmin === 'true' ? true : false);
  },[getCookies('IS_ADMIN')])
  return (
    <>
      <div className="left-custom-navbar position-absolute">
        <div
          className={
            "h-100 position-relative custom-container-left-menu" +
            (isShowMapResult ? " show-map-result" : "")
          }
        >
          <div className="navbar-custom h-100 position-relative" id="accordion">
            <div className="header-navbar-custom position-relative">
              <button
                className="btn "
                data-toggle="collapse"
                data-target="#mapLayersTab"
                aria-expanded="true"
                aria-controls="mapLayersTab"
              >
                {LanguageCollects.planningMapView_map}
              </button>
              <button
                id="ButtonControlMapInfomationTab"
                className="btn collapsed"
                data-toggle="collapse"
                data-target="#mapInfomationTab"
                aria-expanded="false"
                aria-controls="mapInfomationTab"
              >
                {LanguageCollects.planningMapView_infomation}
              </button>
              <button
                className="btn collapsed"
                data-toggle="collapse"
                data-target="#relativePlanningTab"
                aria-expanded="false"
                aria-controls="relativePlanningTab"
                hidden={props.isLeftNavbarHide}
              >
                {LanguageCollects.planningMapView_relatedPlanning}
              </button>
              <button
                id="btnRelativeResultTab"
                className="btn collapsed"
                data-toggle="collapse"
                data-target="#relativeResultTab"
                aria-expanded="false"
                aria-controls="relativeResultTab"
                hidden={props.isLeftNavbarHide}
              >
                {LanguageCollects.planningMapView_result}
              </button>
              <button
                className="btn btn-hide-left-navbar position-absolute"
                onClick={() => handleClickToggleLeftNavBar()}
              >
                <FontAwesomeIcon
                  icon={props.isLeftNavbarHide ? faAngleRight : faAngleLeft}
                  color="white"
                  size="lg"
                />
              </button>
            </div>
            <div hidden={props.isLeftNavbarHide} style={{ overflowY: 'scroll', height: '80%' }}>
              <div
                id="mapLayersTab"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                {props.listGroupLayer.map((data, index: number) => (
                  <RenderMapChild
                    toggleLayerAction={(
                      data: LayerSettingsModels.LayerSettingsModel
                    ) => handleToggleDisplayLayer(data)}
                    toggleLeftNavBarAction={() => handleClickToggleLeftNavBar()}
                    key={index}
                    data={data}
                    showNote={showNote}
                    setShowNote={setShowNote}
                    noteData={noteData}
                    setNoteData={setNoteData}
                  />
                ))}
              </div>
              <div
                id="mapInfomationTab"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <InfoPlanning listMapInfomations={props.listMapInfomations} />
                <InfoDocument planningId={props.planningId} isAdmin={isAdmin} />
                <InfoNote />
              </div>
              <div
                id="relativePlanningTab"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                {props.listPlanningRelationShips?.map((data, index) => (
                  <Related
                    handleClick={handleToggleDisplayBoundaries}
                    key={index}
                    data={data}
                  />
                ))}
                {props.listPlanningRelationShips?.length === 0 && (
                  <p className="p-3 text-center">
                    Không có quy hoạch liên quan nào được tìm thấy
                  </p>
                )}
              </div>
              <div
                id="relativeResultTab"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                {props.informationForTableResult &&
                  props.informationForTableResult.length > 0 ? (
                  <RenderInfoTable
                    handleClick={handleToggleDisplayBoundaries}
                    handleClickShowAllMapResult={handleToggleShowAllMapResult}
                    data={props.informationForTableResult}
                    isShowMapResult={isShowMapResult}
                    headerTable={props.headerTable ? props.headerTable : []}
                    openLayerController={props.openLayerController}
                  />
                ) : (
                  <p className="p-3 text-center">Không có kết quả</p>
                )}
              </div>
            </div>
            <div
              className="header-navbar-custom-icons-style text-center position-absolute"
              hidden={!props.isLeftNavbarHide}
            >
              <button
                title={LanguageCollects.planningMapView_map}
                onClick={() => handleClickIconLeftNavbar("mapLayersTab")}
              >
                <FontAwesomeIcon icon={faMap} color="#00923f" size="lg" />
              </button>
              <button
                title={LanguageCollects.planningMapView_infomation}
                onClick={() => handleClickIconLeftNavbar("mapInfomationTab")}
              >
                <div className="information-circle-warp">
                  <img
                    src={require("../../../assets/icon/information.svg")}
                    alt="Information"
                  />
                </div>
              </button>
              <button
                title={LanguageCollects.planningMapView_relatedPlanning}
                onClick={() => handleClickIconLeftNavbar("relativePlanningTab")}
              >
                <img
                  src={require("../../../assets/icon/document-duplicate.svg")}
                  alt="Document Duplicate"
                />
              </button>
            </div>
          </div>
          <Link to={isAdmin ? "/quan-ly-ho-so?planningId=" + planningModel?.id : "#"}>
            <div
              className={`guide-user guide-user_bottom_80 ${props.isLeftNavbarHide ? "guide-user-hide" : ""
                } position-absolute pb-2 pt-2 pl-3`}
            >
              <FontAwesomeIcon
                icon={faMap}
                color="#747474"
                onClick={onClickIcon}
              />
              <span
                hidden={props.isLeftNavbarHide}
                className="ml-2"
              >
                <span className="bold">Đồ án: </span> {planningModel?.name}
              </span>
            </div>
          </Link>

          <a href={planningModel?.documenOfStatement ? (apiConfig.api + planningModel?.documenOfStatement) : '#'}
            target={planningModel?.documenOfStatement ? '_blank' : ""}
          >
            <div
              className={`guide-user guide-user_bottom_40 ${props.isLeftNavbarHide ? "guide-user-hide" : ""
                } position-absolute pb-2 pt-2 pl-3`}
              style={{ border: '0px' }}
            >
              <FontAwesomeIcon
                icon={faGavel}
                color="#747474"
                onClick={onClickIcon}
              />
              <span
                hidden={props.isLeftNavbarHide}
                className="ml-2"
              >
                <span className="bold">Căn cứ pháp lý: </span>
                {planningModel?.numberOfDecisions}
              </span>
            </div>
          </a>


          <div
            className={`guide-user ${props.isLeftNavbarHide ? "guide-user-hide" : ""
              } position-absolute pb-2 pt-2 pl-3`}
          >
            <FontAwesomeIcon
              icon={faBook}
              color="#747474"
              data-toggle="modal"
              data-target=".bd-example-modal-lg"
            />
            <span
              data-toggle="modal"
              data-target=".bd-example-modal-lg"
              hidden={props.isLeftNavbarHide}
              className="ml-2"
            >
              {LanguageCollects.planningMapView_guideToUse}
            </span>
          </div>
        </div>
      </div>

      <RenderNote showNote={showNote} setShowNote={setShowNote} noteData={noteData} />
    </>
  );
}

function RenderRelatedPlanning(props: any) {
  const data: PlanningRelationModel = props.data;

  return (
    <div className="related-planning-container pt-2 pl-2 pr-2 pb-0">
      <h6 className="mb-0">
        <img
          src={require("../../../assets/icon/circle.png")}
          alt="Circle"
          className="pr-1"
        />
        {data.planningTypeName}
      </h6>
      <div className="pl-3 pr-2 pt-2 pb-0">
        {data.planningMapModels.map((planningData, index) => (
          <RenderItemFieldRelatedPlanning
            handleClick={props.handleClick}
            key={index}
            data={planningData}
          />
        ))}
      </div>
    </div>
  );
}

function RenderItemFieldRelatedPlanning(props: any) {
  const data: PlanningMapModels = props.data;
  const isChecked = useRef(false);
  const handleClick = () => {
    if (data.boundariesView) {
      props.handleClick(data.boundariesView, !isChecked.current);
    } else {
      !isChecked.current &&
        ShowNotification(
          "Bạn phải cài đặt ranh giới liên quan",
          NotificationMessageType.Warning
        );
    }
    isChecked.current = !isChecked.current;
  };
  if (!(data && data.planningName)) return null;
  return (
    <label className="cursor-pointer">
      <input
        type="checkbox"
        id={`planning-relationship-checkbox${data.planningName}`}
        onClick={() => handleClick()}
      />{" "}
      {data.planningName}
    </label>
  );
}

function RenderMapChild(props: any) {
  const data: LayerCategoryModels.LayerCategoryModel = props.data;
  const [layerSetting, setLayerSetting] = useState<
    LayerSettingsModels.LayerSettingsModel[]
  >(props.data.layer_settings);
  const [labelFlexible, setLabelFlexible] = useState("Chọn tất");

  function toggleSelectAll() {
    if (isEveryCheckboxChecked()) {
      selectOrDeselectAllCheckBox(false);
    } else {
      selectOrDeselectAllCheckBox();
    }
  }

  function isEveryCheckboxChecked() {
    let result = true;
    const listCheckbox = Array.from(
      document.querySelectorAll(`input[data-select-id="${data.folder_name}"]`)
    );
    listCheckbox.map((checkbox) => {
      if (!(checkbox as HTMLInputElement).checked) {
        result = false;
        return true;
      }
    });
    return result;
  }
  function selectOrDeselectAllCheckBox(isSelect = true) {
    const listCheckbox = Array.from(
      document.querySelectorAll(`input[data-select-id="${data.folder_name}"]`)
    );
    listCheckbox.map((checkboxElement) => {
      const checkbox = checkboxElement as HTMLInputElement;
      if (checkbox.checked != isSelect) checkbox.click();
    });
  }

  function handleClickCheckbox(LayerIndex: number, newValue: boolean) {
    const checkboxAll = document.querySelectorAll(
      `input[data-select-all-id="${data.folder_name}"]`
    )[0] as HTMLInputElement;
    const oldLayerSetting = [...layerSetting];
    oldLayerSetting[LayerIndex].is_check = newValue;
    setLayerSetting(oldLayerSetting);
    props.toggleLayerAction(oldLayerSetting[LayerIndex]);
    if (checkboxAll) {
      if (isEveryCheckboxChecked()) {
        checkboxAll.checked = true;
        setLabelFlexible("Bỏ chọn tất cả");
      } else {
        checkboxAll.checked = false;
        setLabelFlexible("Chọn tất");
      }
    }
    if (isMobile) {
      props.toggleLeftNavBarAction();
    }
  }

  const handleShowNote = (item: any) => {
    console.log(props.showNote)
    props.setShowNote(true)
    props.setNoteData(item)
  }
  return (
    <div className="render-map-child-container pt-2 pl-2 pr-2">
      <details>
        <summary className="mb-1">
          {data.folder_name}
          <img
            className="position-absolute icon-dropdow right-position"
            src={require("../../../assets/icon/arrow-down.png")}
            alt="Arrow Down"
          />
          <img
            className="position-absolute icon-dropright right-position"
            src={require("../../../assets/icon/arrow-right.png")}
            alt="Arrow Right"
          />
        </summary>
        <div className="select-container">
          {data.layer_settings.length > 1 && (
            <label className="d-block">
              <input
                type="checkbox"
                className="mr-1"
                data-select-all-id={data.folder_name}
                onClick={() => toggleSelectAll()}
              />
              {labelFlexible}
            </label>
          )}
          {layerSetting.map((item, index: number) => (
            <div key={index} className="d-block">
              <label >
                <input
                  type="checkbox"
                  data-select-id={data.folder_name}
                  checked={item.is_check}
                  onChange={() => { }}
                  data-left-menu-layer-id={item.name}
                  className="mr-1"
                  onClick={() => handleClickCheckbox(index, !item.is_check)}
                />
                {item.name}&ensp;
              </label>
              <span onClick={() => handleShowNote(item)} style={{ cursor: 'pointer' }}>
                {item.files.fileId ? <FontAwesomeIcon icon={faInfoCircle} color="#00786E" /> : ''}
              </span>
            </div>
          ))}
        </div>
      </details>
    </div>
  );
}

function RenderNote(props: any) {

  return (props.showNote &&
    <div className="note_">

      {props.noteData.files.filePreview &&
        <>
          <div className="close_note">
            <h6>Chú giải</h6>
            <h5 onClick={() => props.setShowNote(false)}>X</h5>
          </div>
          <div className="note_img">
            <img
              src={apiConfig.api + props.noteData.files.filePreview}
              alt={props.noteData.files.fileName}
            />
          </div>
        </>
      }
    </div>
  )
}

function RenderInfoTable(props: any) {
  const {
    data,
    handleClickShowAllMapResult,
    isShowMapResult,
    headerTable,
    openLayerController,
  } = props;

  const onDownloadExcelOffline = () => {
    var tab_text = "<table border='2px'><thead><tr>";
    var _header = "";
    headerTable &&
      headerTable.forEach((_item: any, _index: number) => {
        const _th =
          "<th style='color:white; background-color:#007bff; height:70px;'>" +
          (_item.alias || "") +
          "</th>";
        _header += _th;
      });
    tab_text += _header + "</tr></thead><tbody>";

    var _body = "";
    data &&
      data.forEach((_item: any, _i: number) => {
        _body += "<tr>";
        var _row = "";
        headerTable.forEach((_title: any, _index: number) => {
          const _td = "<td>" + (_item[_title.col] || "") + "</td>";
          _row += _td;
        });
        _body += _row + "</tr>";
      });

    tab_text += _body + "</tbody></table>";
    tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
    tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
    tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

    const sa = window.open(
      "data:application/vnd.ms-excel," + encodeURIComponent(tab_text)
    );
    return sa;
  };

  const onRemoveHighlight = () => {
    openLayerController.handleOnOffViewInfomation(true);
  };

  return (
    <div className="render-map-child-container pt-2 pl-2 pr-2">
      <div className="d-flex align-items-center justify-content-between pt-2 pb-2 pl-2">
        <div className="map-result-number d-flex align-items-center">
          <label className="mb-0">
            Có <b>{data.length}</b> kết quả
          </label>
          <span
            onClick={onDownloadExcelOffline}
            className="text-success ml-3 export-excel"
            data-tip="Xuất file excel"
          >
            <FontAwesomeIcon icon={faFileExcel} />
          </span>

          <span
            onClick={onRemoveHighlight}
            className="text-danger ml-3 remove"
            data-tip="Xóa kết quả tìm kiếm trên bản đồ"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </span>
          <ReactTooltip place="right" effect="solid" />
        </div>
        <div className="map-result-view">
          {!isShowMapResult ? (
            <img
              src={require("../../../assets/icon/last.svg")}
              alt="Last"
              className="img-last"
              onClick={handleClickShowAllMapResult}
            ></img>
          ) : (
            <img
              src={require("../../../assets/icon/last.svg")}
              alt="Last"
              className="img-last-reverse"
              onClick={handleClickShowAllMapResult}
            ></img>
          )}
          <img
            src={require("../../../assets/icon/close-black.svg")}
            alt="Close"
            className="img-close ml-3"
          ></img>
        </div>
      </div>

      <table className="table table-map-result">
        <thead>
          <tr>
            {headerTable &&
              headerTable.length > 0 &&
              headerTable.map((item: any, index: number) => (
                <th
                  scope="col"
                  key={index}
                  className="text-truncate bg-primary text-white"
                >
                  {item.alias}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((item: any, index: number) => (
              <tr key={index}>
                {headerTable &&
                  headerTable.length > 0 &&
                  headerTable.map((item1: any, index: number) => (
                    <th scope="row" key={index}>
                      {item[item1.col]}
                    </th>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  listMapInfomations: state.mapData.infomations,
  informationForTableResult: state.mapData.dataForTable,
  listPlanningRelationShips: state.mapData.planningRelationShips,
  listPlanningBoundaries: state.mapData.listPlanningBoundaries,
  openLayerController: state.openlayer.openLayerController,
  headerTable: state.mapData.headerTable,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetListPlanningRelationByPlanningId:
        MapDataStore.GetListPlanningRelationByPlanningId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuView);
