import React, { useState } from "react";
import "./left-menu.scss";
import UrlCollect from "../../../common/url-collect";
import { Link } from "react-router-dom";
import SearchPlanningView from "../search-planning-general/search-planning-name.view";
import SearchByMyPosition from "../../look-up-planning/child-modules/search-by-position/search-by-position.view";
import SearchByAddress from "../../look-up-planning/child-modules/search-address/search-address.view";
import { connect } from "react-redux";
import PlanningCategoriesModel from "../../../models/map-data-model-b/planning-categories-model";
import OpenlayerController from "../../../models/open-layer/openlayer-controller-custom.model";
import history from "../../../common/history";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../languages/vi-VN.json";
import * as mapHelper from "../../../common/map";
import { SearchByDistrict } from "../../look-up-planning/child-modules/planning-district/search-district.view";
import {
  NotificationMessageType,
  NotificationPosition,
} from "../../../utils/configuration";

interface InitLeftMenuViewProps {
  isDisplaySearchResult: boolean;
  resetSearchDisplayMode: Function;
  listPlanningCategoriesAll?: PlanningCategoriesModel[];
  openLayerController?: OpenlayerController;
  planningType: any;
  listPolygon: any;
}

function LeftMenuView(props: InitLeftMenuViewProps) {
  const [show, setShow] = useState(true);
  const [districtSelect, setDistrictSelect] = useState('');
  const [name, setName] = useState('');

  let commentInput: any = null;

  function handleResetDisplaySearchMode() {
    if (props.isDisplaySearchResult) props.resetSearchDisplayMode();
  }

  function handleSearchCoordinate(event: any) {
    event.preventDefault();
    props.openLayerController?.handleSearchCoordinate(
      commentInput && commentInput.value ? commentInput.value.trim() : ""
    );
  }
  //
  function checkAddressInPolyon(x: any, y: any) {
    let isInside = false;
    props.listPolygon &&
      props.listPolygon.map((itemPolygon: any) => {
        if (
          mapHelper.isPointInPolygon(y, x, itemPolygon)
        ) {
          isInside = true;
          return;
        }
      });
    if (isInside) {
      history.push(
        `${UrlCollect.SyntheticPlanning}?lng=${x}&lat=${y}&searchType=place`
      )
      return true;
    } else {
      ShowNotification(
        viVN.Errors.CoordinateOutSidePolygon,
        NotificationMessageType.Warning,
        NotificationPosition.Center
      );
    }
    return false;
  }
  const onSubmit = () => {
    //setName(data.name);
    history.push(`${UrlCollect.PlanningMapViewDetail}?key=&name=${name}&district=${districtSelect}`)
  };
  return (
    <div className="left-menu-custom-container h-100 position-relative">
      <div className="search-groups">
        <SearchByDistrict
          onSubmit={onSubmit}
          setDistrictSelect={(data : any) => setDistrictSelect(data)}
          name={name}
          setName={(data : any) => setName(data)}
        />
        {/* <SearchPlanningView />
        <div className="row no-gutters search-container mt-2 align-items-center">
          <div className="col-10">
            {show ? (
              <SearchByAddress
                onSelectAddress={(x: any, y: any) => checkAddressInPolyon(x, y)}
              />
            ) : (
              <form
                onSubmit={handleSearchCoordinate}
                className="row no-gutters search-container align-items-center"
              >
                <input
                  type="text"
                  ref={(input: any) => {
                    commentInput = input;
                  }}
                  className="form-control"
                  placeholder="Nhập toạ độ VN2000 (Toạ độ X, Toạ độ Y)"
                />
              </form>
            )}
          </div>
          <div className="col-2 text-right">
            <SearchByMyPosition
              classCustom="border-0 search-by-my-position"
              show={show}
              onShow={setShow}
            />
          </div>
        </div>
        <div className="mt-2">
          <SearchByDistrict />
        </div> */}
      </div>
      <div
        className={`title-container ${props.isDisplaySearchResult ? "" : "p-2 disabled"
          }`}
        onClick={() => handleResetDisplaySearchMode()}
      >
        Tổng hợp quy hoạch
      </div>

      {props.planningType.map((item: any) => (
        <RenderGroupPlanning
          isDisplaySearchResult={props.isDisplaySearchResult}
          titleGroup={item.name}
          key={item.id}
          urlGroup={UrlCollect.PlanningMap + "?key=" + item.id}
          listLayers={item.plannings}
        />
      ))}
    </div>
  );
}

interface InitRenderGroupPlanningProps {
  titleGroup: string;
  listLayers: any[];
  urlGroup: string;
  isDisplaySearchResult: boolean;
}

function RenderGroupPlanning(props: InitRenderGroupPlanningProps) {
  return (
    <div className="render-group-planning">
      <Link to={props.urlGroup} className="title-container p-2">
        {props.titleGroup}
      </Link>
      {props.isDisplaySearchResult &&
        props.listLayers &&
        props.listLayers.length > 0 && (
          <div className="pl-3 pr-2 pt-2 pb-2">
            {props.listLayers.map((layerObject, index) => (
              <label
                key={index}
                className="text-truncate"
                title={layerObject.name}
              >
                <input type="checkbox" /> {layerObject.name}
              </label>
            ))}
          </div>
        )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  listPlanningCategoriesAll: state.mapData.listPlanningCategoriesAll,
  openLayerController: state.openlayer.openLayerController,
  planningType: state.statement.planningType,
  listPolygon: state.mapData.listPolygon,
});

export default connect(mapStateToProps)(LeftMenuView);
